import { createContext } from 'react';
import { IUserAccountGeneral } from 'types/user';

export type RegisterValue = {
    username: string;
    name: string;
    phone: string;
    password: string;
};

export type AuthContextValue = {
    authLoading: boolean;
    isLogined: boolean;
    accessToken: string;
    user: IUserAccountGeneral;
    blockList: string[];
    stakes: { name: string; value: number }[];
    disabledMatch: string[];
    login: (username: string, password: string, remember: boolean) => Promise<void>;
    updateUser: (params: { kyc: boolean }) => Promise<void>;
    register: (registerValue: RegisterValue) => Promise<void>;
    logout: () => void;
    accountBlock: () => void;
    updateStakes: (stakes: { name: string; value: number }[]) => void;
};

const AuthContext = createContext<AuthContextValue | null>(null);

export { AuthContext };
