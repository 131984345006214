import axios from 'axios';
// config
import { HOST_API_KEY } from 'config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
    (response) => response,
    // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
    (error) => {
        let message = '';
        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
            if (error.response.data.code === 401 && message === 'Please authenticate') {
                window.location.href = '/';
                Promise.reject(message);
            }
        } else if (error.response && error.response.data) {
            message = error.response.data;
        } else {
            message = 'Something went wrong';
        }
        return Promise.reject(message);
    }
);

export default axiosInstance;

export const ASSETS = (name: string) => (name ? `${HOST_API_KEY}/${name}` : '');
