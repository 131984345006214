import { useState } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import ReactPixel from 'react-facebook-pixel';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

// hook
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useSnackbar } from 'notistack';
import { useBoolean } from 'hooks/use-boolean';
import { reSendOTP, registerCheck, sendOTP, verifyOTP } from 'utils/apis';
import { grey } from '@mui/material/colors';

type FormValuesProps = {
    username: string;
    name: string;
    phone: string;
    password: string;
    confirm: string;
};

const RegisterForm = () => {
    const { register } = useAuth();
    const { onChangeModal, config } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    const isVerify = useBoolean();
    const otpPage = useBoolean();
    const hidePassword = useBoolean(true);
    const hideConfirm = useBoolean(true);
    const [otp, setOtp] = useState('');

    const RegisterSchema = Yup.object().shape({
        username: Yup.string().required('Username is required').min(4).max(20),
        name: Yup.string().required('Full name is required'),
        phone: Yup.string().required('Phone Number is required').min(10).max(15),
        password: Yup.string().required('Password is required'),
        confirm: Yup.string().required('Confirm Password is required')
    });

    const defaultValues = {
        username: '',
        name: '',
        phone: '',
        password: '',
        confirm: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(RegisterSchema),
        defaultValues
    });

    const {
        watch,
        reset,
        setValue,
        setError,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    const value = watch();

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (data.password !== data.confirm) {
                setError('confirm', { message: 'Check password again' });
                return;
            }
            if (config && config.IsOTPVerification) {
                const vaildData = await registerCheck({
                    username: data.username.toLowerCase().trim(),
                    name: data.name.trim(),
                    phone: data.phone.trim(),
                    password: data.password
                });
                if (vaildData.status) {
                    await sendOTP({ phone: data.phone });
                    otpPage.onTrue();
                }
                return;
            } else {
                await register({
                    username: data.username.toLowerCase().trim(),
                    name: data.name.trim(),
                    phone: data.phone.trim(),
                    password: data.password
                });
                ReactPixel.track('PageView');
                reset();
                enqueueSnackbar('Succss!', { variant: 'success' });
                goLogin();
            }
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
        }
    });

    const otpResend = async () => {
        try {
            if (!value.phone) {
                enqueueSnackbar('Phone Number required', { variant: 'error' });
                return;
            }
            await reSendOTP({ phone: value.phone });
            enqueueSnackbar('Resent Successfully', { variant: 'success' });
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
        }
    };

    const otpVerify = async () => {
        try {
            if (!otp) return;
            isVerify.onTrue();
            const optData = await verifyOTP({ phone: value.phone, otp });
            if (optData.status) {
                await register({
                    username: value.username.toLowerCase().trim(),
                    name: value.name.trim(),
                    phone: value.phone.trim(),
                    password: value.password
                });
                isVerify.onFalse();
                enqueueSnackbar('Succss!', { variant: 'success' });
                goLogin();
            }
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
            isVerify.onFalse();
        }
    };

    const goLogin = () => {
        onChangeModal({ registerModal: false, loginModal: true });
    };

    const otpInput = (
        <Stack spacing={2}>
            <TextField
                variant="filled"
                label="OTP Code"
                value={otp}
                onChange={(e: any) => setOtp(e.target.value)}
                sx={{
                    width: '100%',
                    borderRadius: 4,
                    overflow: 'hidden',
                    '& .MuiInputBase-root': {
                        borderRadius: 4,
                        border: '1px solid',
                        borderColor: grey[400]
                    }
                }}
                inputProps={{
                    autoComplete: 'off'
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon icon="fa6-solid:comment-sms" color="#637381" width="18" height="18" />
                        </InputAdornment>
                    )
                }}
            />
            <Box>
                <Button size="small" variant="contained" color="inherit" onClick={otpResend} sx={{ borderRadius: 2 }}>
                    <Icon icon="icon-park-twotone:timer" color="#637381" width="18" height="18" />
                    <Typography variant="body2">Resend</Typography>
                </Button>
            </Box>
            <Stack direction="row" spacing={3}>
                <Button
                    color="warning"
                    variant="contained"
                    onClick={otpPage.onFalse}
                    sx={{
                        width: 1,
                        borderRadius: 4,
                        background: (theme) =>
                            `linear-gradient(138.38deg, ${theme.palette.warning.light} 12.51%, ${theme.palette.warning.dark} 154.03%)`
                    }}
                >
                    Back
                </Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    sx={{
                        width: 1,
                        borderRadius: 4,
                        background: (theme) =>
                            `linear-gradient(138.38deg, ${theme.palette.success.light} 12.51%, ${theme.palette.success.dark} 154.03%)`
                    }}
                    loading={isVerify.value}
                    onClick={otpVerify}
                >
                    Verify
                </LoadingButton>
            </Stack>
        </Stack>
    );

    const renderInputs = (
        <>
            <Stack rowGap={3} columnGap={2} sx={{ width: '100%' }} display="grid" width="100%">
                <RHFTextField
                    fullWidth
                    variant="filled"
                    name="username"
                    label="Username"
                    onChange={(e) => setValue('username', e.target.value.replaceAll(' ', ''))}
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="fa6-solid:user" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        )
                    }}
                />
                <RHFTextField
                    fullWidth
                    variant="filled"
                    name="name"
                    label="Full Name"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="fa6-solid:user" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        )
                    }}
                />
                <RHFTextField
                    fullWidth
                    variant="filled"
                    name="phone"
                    label="Phone Number"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="ic:baseline-phone" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        )
                    }}
                />
                <RHFTextField
                    fullWidth
                    variant="filled"
                    type={hidePassword.value ? 'password' : 'text'}
                    name="password"
                    label="Password"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="mdi:password" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={hidePassword.onToggle}>
                                    <Icon
                                        icon={hidePassword.value ? 'mdi:eye-off' : 'mdi:eye'}
                                        color="#637381"
                                        width="23"
                                        height="23"
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <RHFTextField
                    fullWidth
                    variant="filled"
                    type={hideConfirm.value ? 'password' : 'text'}
                    name="confirm"
                    label="Confirm Password"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="teenyicons:password-solid" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={hideConfirm.onToggle}>
                                    <Icon
                                        icon={hideConfirm.value ? 'mdi:eye-off' : 'mdi:eye'}
                                        color="#637381"
                                        width="23"
                                        height="23"
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
            <LoadingButton
                loading={isSubmitting}
                size="large"
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                    my: 2,
                    borderRadius: 4,
                    background: `linear-gradient(138.38deg, #6D96FC 12.51%, #0134A9 154.03%)`
                }}
            >
                Register
            </LoadingButton>
        </>
    );

    return (
        <Stack spacing={3} sx={{ width: { sm: '50%', xs: '100%' } }} justifyContent="center">
            <FormProvider methods={methods} onSubmit={onSubmit}>
                {otpPage.value ? otpInput : renderInputs}
                {!otpPage.value && (
                    <Typography
                        component="div"
                        sx={{ color: 'action.active', textAlign: 'center', userSelect: 'none' }}
                    >
                        Already have an account? &nbsp;
                        <Typography
                            component="span"
                            onClick={() => onChangeModal({ registerModal: false, loginModal: true })}
                            noWrap
                            sx={{ fontWeight: 'bold', fontSize: 14, color: 'primary.light', cursor: 'pointer' }}
                        >
                            Login
                        </Typography>
                    </Typography>
                )}
            </FormProvider>
        </Stack>
    );
};

export default RegisterForm;
